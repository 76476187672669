<template>
  <Main>
    <ToolsPanel :breadCrumbFirst="'Month Plan'" :title="'Month Plan'">
      <!-- <div class="list__buttons">
        <MainButton @click.native="handleCreate()">Create</MainButton>
      </div> -->
    </ToolsPanel>
    <ContentContainer>
      <div class="list">
        <div v-if="plans">
          <div class="list__top">{{ plans.month }} {{ plans.year }}</div>
          <div class="list__sort-items">
            <div class="list__sort-item">
              <div v-click-outside="handleCloseListCategory" class="list__sort-item-container">
                <div @click="handleOpenListCategory" class="list__sort-item-toggle">
                  <div class="list__sort-item-title">
                    {{ filterCategoryName || "Filter by category" }}
                  </div>
                  <div :class="['list__sort-item-icon', triggerListCategory && 'active']">
                    <IconSelect />
                  </div>
                </div>
                <div
                  @click="handleClearCategoryFilter"
                  v-if="filterCategoryName != null"
                  class="list__sort-clear"
                >
                  <IconCancel />
                </div>
                <div v-if="triggerListCategory" class="list__sort-item-list">
                  <div
                    v-for="category in categoriesTree"
                    :key="category.id"
                    class="list__list-item"
                  >
                    <div v-if="category.children.length > 0" class="list__sort-item-list_second">
                      <div
                        v-for="categorySecond in category.children"
                        :key="categorySecond.id"
                        class="list__list-item"
                      >
                        <div
                          @click="handleChooseCategory(categorySecond)"
                          class="list__list-item-container"
                        >
                          {{ categorySecond.name }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="list__progress">
            <div class="list__progress-item">
              <div class="list__progress-item-title">Waiting for approval</div>
              <div class="list__progress-bar-container">
                <div
                  class="list__progress-bar-inner"
                  :style="{
                    width: `${(plans.statistics.waitingForApproval / plans.statistics.all) * 100}%`,
                  }"
                ></div>
              </div>
              <div class="list__progress-bar-desc">
                {{
                  `${Math.round(
                    (plans.statistics.waitingForApproval / plans.statistics.all) * 100,
                  )}%`
                }}
                completed
              </div>
            </div>

            <div class="list__progress-item">
              <div class="list__progress-item-title">In work</div>
              <div class="list__progress-bar-container">
                <div
                  class="list__progress-bar-inner"
                  :style="{
                    width: `${(plans.statistics.inWork / plans.statistics.all) * 100}%`,
                  }"
                ></div>
              </div>
              <div class="list__progress-bar-desc">
                {{ `${Math.round((plans.statistics.inWork / plans.statistics.all) * 100)}%` }}
                completed
              </div>
            </div>

            <div class="list__progress-item">
              <div class="list__progress-item-title">Published</div>
              <div class="list__progress-bar-container">
                <div
                  class="list__progress-bar-inner"
                  :style="{
                    width: `${(plans.statistics.published / plans.statistics.all) * 100}%`,
                  }"
                ></div>
              </div>
              <div class="list__progress-bar-desc">
                {{ `${Math.round((plans.statistics.published / plans.statistics.all) * 100)}%` }}
                completed
              </div>
            </div>
          </div>

          <div v-if="plans.plannedTemplatesByCategory" class="table-category">
            <div @click="openCategoryStatistic" class="table-category__button">
              Category statistic
              <div :class="['table-category__button-icon', isCategoryStatistic && 'active']">
                <IconSelect />
              </div>
            </div>
            <table v-if="isCategoryStatistic">
              <tr class="header">
                <td>Category</td>
                <td>New / Complex</td>
                <td>New / Simple</td>
                <td>New / Bonus</td>
                <td>Existing</td>
                <td>Free</td>
                <td>Premium</td>
                <td>Total</td>
              </tr>
              <tr v-for="category in plans.plannedTemplatesByCategory" :key="category.id">
                <td>
                  <b>{{ category.name }}</b>
                </td>
                <td>{{ category.newComplex }}</td>
                <td>{{ category.newSimple }}</td>
                <td>{{ category.newBonus }}</td>
                <td>{{ category.existingUpdate }}</td>
                <td>{{ category.free }}</td>
                <td>{{ category.premium }}</td>
                <td>{{ category.total }}</td>
              </tr>
            </table>
          </div>

          <div class="list__items sticky">
            <div v-for="item in plansList" :key="item.id" :class="['list__item']">
              <div class="list__item-top">
                <div class="list__item-top-title">{{ item.name }}</div>
                <div>Plan Total: {{ item.statistics.all }}</div>
                <div class="list__item-top-statistic">
                  <div class="list__item-top-statistic-icon green">
                    {{ item.statistics.published }}
                  </div>
                  <div class="list__item-top-statistic-icon red">
                    {{ item.statistics.withoutTemplate }}
                  </div>
                  <div class="list__item-top-statistic-icon">
                    {{ item.statistics.exceptPublished }}
                  </div>
                </div>
                <div class="list__item-nav">
                  <div @click="handleCreate(item.id)" class="list__item-nav-button">Add Task</div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="list__type-items" v-if="plansItemsTypesList.length">
              <div class="list__type-item" v-for="type in plansItemsTypesList" :key="type.id">
                <div class="list__type-title">{{ type.name }}</div>
                <div class="list__items">
                  <div v-for="item in plansList" :key="item.id" :class="['list__item']">
                    <div v-if="getCount(type.id, item.items) > 0" class="list__item-count">
                      {{ getCount(type.id, item.items) }}
                    </div>
                    <div v-if="item.items.length > 0" class="list__item-elements">
                      <div v-for="elem in item.items" :key="elem.id">
                        <div
                          v-if="type.id == elem.itemType.id"
                          :class="['list__item-element', getColor(elem)]"
                        >
                          <div
                            v-if="elem"
                            :class="['list__item-elem-badge', elem.premium ? 'warning' : 'primary']"
                          >
                            {{ elem.premium ? "Premium" : "Free" }}
                          </div>
                          <router-link
                            target="_blank"
                            :to="goToKeyword(elem.keyword.id)"
                            class="list__item-element-link with-icon"
                            >{{ elem.keyword.keyword }} ({{ elem.keyword.templatesCount }})
                            <div
                              v-if="!elem.keyword.attributesFilled"
                              class="list__item-element-link-icon"
                            >
                              !
                            </div>
                          </router-link>
                          <div
                            v-if="elem.keyword.seasonality && elem.keyword.seasonality.length > 0"
                            class="list__item-element-text-season"
                          >
                            {{ getMonthString(elem.keyword.seasonality) }}
                          </div>
                          <div v-if="elem.suggestedTemplateName" class="list__item-element-text">
                            Suggested Template Name: <br />
                            {{ elem.suggestedTemplateName }}
                          </div>
                          <a
                            :href="elem.keyword.ahrefsLink"
                            target="_blank"
                            class="list__item-element-link"
                          >
                            Ahrefs Link
                          </a>
                          <router-link
                            target="_blank"
                            class="list__item-element-link"
                            :to="goToTemplate(elem.template.id)"
                            v-if="elem.template && elem.itemType.id != 4"
                          >
                            <b>status:</b> {{ elem.template.status.name }}
                            <span
                              v-if="elem.template.statusChangedAt && elem.template.status.id != 6"
                            >
                              ({{ getDifferenceInDays(elem.template.statusChangedAt) }})
                            </span>
                            <span v-if="elem.template.status.id == 6">
                              ({{ elem.template.publishedAt.split(" ")[0] }})
                            </span>
                          </router-link>
                          <router-link
                            target="_blank"
                            class="list__item-element-link"
                            :to="goToTemplate(elem.template.id)"
                            v-if="elem.template && elem.itemType.id == 4"
                          >
                            <b>update status:</b>
                            {{
                              elem.template.secondaryStatus
                                ? elem.template.secondaryStatus.name
                                : "no status"
                            }}
                          </router-link>

                          <template
                            v-if="elem.template && elem.template.status.id == 6 && siteId == 1"
                          >
                            <a
                              target="_blank"
                              class="list__item-element-link"
                              :href="`${publicUrl}${elem.template.url}`"
                              >Template Public Link</a
                            >
                          </template>
                          <div @click="handleEdit(item.id, elem.id, elem)" class="list__item-edit">
                            <svg
                              width="10"
                              height="10"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M0 19.9999L5.39452 19.4417L0.558452 14.6055L0 19.9999Z"
                                fill="#ffffff"
                              />
                              <path
                                d="M12.0017 3.16246L16.8378 7.99853L6.32444 18.5118L1.48828 13.6758L12.0017 3.16246ZM19.336 5.50018C20.2213 4.61491 20.2217 3.17955 19.336 2.29401L17.706 0.66402C16.8208 -0.221431 15.3852 -0.221249 14.4999 0.66402L12.923 2.24101L17.7592 7.07699L19.336 5.50018Z"
                                fill="#ffffff"
                              />
                            </svg>
                          </div>

                          <div @click="handleDelete(elem.id)" class="list__item-delete">
                            <svg
                              width="10"
                              height="13"
                              viewBox="0 0 14 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12.7727 2.26059H10.631V2.14844C10.631 0.963745 9.66724 0 8.48254 0H5.50891C4.32422 0 3.36047 0.963745 3.36047 2.14844V2.26059H1.21875C0.571625 2.26059 0.046875 2.78519 0.046875 3.43246V4.32571C0.046875 4.54147 0.221741 4.71634 0.4375 4.71634H13.554C13.7697 4.71634 13.9446 4.54147 13.9446 4.32571V3.43246C13.9446 2.78519 13.42 2.26059 12.7727 2.26059ZM4.53235 2.14844C4.53235 1.60995 4.97043 1.17188 5.50891 1.17188H8.48254C9.02103 1.17188 9.45911 1.60995 9.45911 2.14844V2.26059H4.53235V2.14844Z"
                                fill="#ffffff"
                              />
                              <path
                                d="M0.552734 5.88672L1.49954 19.4533C1.52106 19.7603 1.77634 19.9985 2.08411 19.9985H11.9076C12.2153 19.9985 12.4706 19.7603 12.492 19.4533L13.4386 5.88672H0.552734ZM4.67688 18.0057C4.66849 18.0062 4.65994 18.0063 4.65155 18.0063C4.33951 18.0063 4.07996 17.76 4.06668 17.4453L3.67606 8.26556C3.66217 7.94223 3.91318 7.66895 4.23651 7.65521C4.56 7.64194 4.83313 7.89249 4.84686 8.21582L5.23749 17.3955C5.25122 17.7188 5.00021 17.992 4.67688 18.0057ZM7.5817 17.4204C7.5817 17.744 7.3194 18.0063 6.99576 18.0063C6.67212 18.0063 6.40982 17.744 6.40982 17.4204V8.24069C6.40982 7.91705 6.67212 7.65475 6.99576 7.65475C7.3194 7.65475 7.5817 7.91705 7.5817 8.24069V17.4204ZM9.92499 17.4453C9.91156 17.7602 9.65201 18.0063 9.33997 18.0063C9.33157 18.0063 9.32303 18.0062 9.31464 18.0059C8.9913 17.9921 8.7403 17.7188 8.75403 17.3955L9.14465 8.21582C9.15854 7.89249 9.43213 7.64163 9.755 7.65537C10.0783 7.6691 10.3293 7.94223 10.3156 8.26556L9.92499 17.4453Z"
                                fill="#ffffff"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="list__no-have" v-else>No Content Plans found by this parameter.</div>
      </div>
    </ContentContainer>
    <ModalError
      :error-modal="false"
      :handle-close-error-modal="
        () => {
          this.error = {};
        }
      "
    />
    <MainModal v-if="deleteModal" :on-close="handleCloseConfirmModal" :modal-center="true">
      <div class="list__confirm">
        <div class="list__confirm-title">Do you want to delete the Task?</div>
        <div class="list__confirm-buttons">
          <div class="list__confirm-button">
            <MainButton buttonWhite @click.native="handleCloseConfirmModal">CANCEL</MainButton>
          </div>
          <div class="list__confirm-button">
            <MainButton @click.native="handleDeleteTask" :button-cancel="true">DELETE</MainButton>
          </div>
        </div>
      </div>
    </MainModal>

    <MainModal
      v-if="createModal || editModal"
      :on-close="handleCloseCreateModal"
      :modal-center="true"
    >
      <div class="list__confirm">
        <p class="form-create__label">Keyword</p>
        <div class="input-container">
          <div class="form-create__keyword">
            <input
              type="text"
              @focus="handleFocus"
              @blur="handleBlur"
              @input="debouncedHandleInput"
              v-model="formDataToSend.keyword_name"
              :class="['form-create__keyword-input', formDataToSend.keyword_id && 'filled']"
            />

            <div
              v-if="formDataToSend.attributes_filled && formDataToSend.keyword_id"
              class="form-create__keyword-text green"
            >
              Attributes filled
            </div>
            <div
              v-if="!formDataToSend.attributes_filled && formDataToSend.keyword_id"
              class="form-create__keyword-text red"
            >
              Attributes no filled
            </div>

            <div
              v-if="formDataToSend.seasonality && formDataToSend.seasonality.length > 0"
              class="list__item-element-text-season"
            >
              {{ getMonthString(formDataToSend.seasonality) }}
            </div>

            <div v-if="keywordList" class="form-create__keyword-list">
              <template v-if="keywordArray.length > 0">
                <div
                  @click="handleChooseItem(item)"
                  v-for="item in keywordArray"
                  :key="item.id"
                  class="form-create__keyword-item"
                >
                  {{ item.keyword.toLowerCase() }}
                </div>
              </template>
              <div v-else class="form-create__keyword-item">NO keywords</div>
            </div>
            <Notification :error-messages="validationErrors['keywordId']" />
          </div>
        </div>

        <div class="input-container">
          <MainCheckbox
            :label="'Is new product'"
            :id="'is_new_product'"
            :set-value="formDataToSend"
          />
        </div>

        <p class="form-create__label">Plans Items Types</p>
        <div class="input-container">
          <MainSelect
            :id="'item_type'"
            :placeholder="'Choose'"
            :option-list="plansItemsTypesList"
            :set-value="formDataToSend"
            :value="formDataToSend.item_type"
          />
          <Notification :error-messages="validationErrors['itemTypeId']" />
        </div>

        <template v-if="!formDataToSend.is_new_product">
          <p class="form-create__label">Template Url</p>
          <p>ID Template: {{ formDataToSend.template_id }}</p>
          <div class="input-container">
            <InputText
              class="green"
              :set-value="formDataToSend"
              :id="'template_url'"
              :label="'template_url'"
              :type="'text'"
              :placeholder="'Template Url'"
              :error-messages="validationErrors['templateUrl']"
              isBordered
            />
          </div>
          <div class="form-create__button-group">
            <MainButton @click.native="setTemplate">{{
              formDataToSend.template_id ? "Change Template" : "Set Template"
            }}</MainButton>
          </div>
        </template>

        <div class="input-container">
          <MainCheckbox :label="'Premium'" :id="'premium'" :set-value="formDataToSend" />
          <Notification :error-messages="validationErrors['premium']" />
        </div>

        <p class="form-create__label">Suggested Template Name</p>
        <div class="input-container">
          <InputText
            :set-value="formDataToSend"
            :id="'suggested_template_name'"
            :label="'suggested_template_name'"
            :type="'text'"
            :placeholder="'Suggested template name'"
            :error-messages="validationErrors['suggestedTemplateName']"
            isBordered
          />
        </div>

        <template v-if="attributesArray.length > 0">
          <p class="form-create__label">Attributes</p>
          <div class="input-container checkbox-container">
            <div v-for="item in attributesArray" :key="item.id" class="checkbox-container__item">
              <MainCheckbox
                :label="item.name"
                :id="item.id + ''"
                :set-value="formDataToSend.attributes"
              />
            </div>
          </div>
        </template>

        <p class="form-create__label">Selected Size</p>
        <div class="input-container">
          <MainSelect
            :id="'size'"
            :placeholder="'Choose'"
            :option-list="sizesList"
            :set-value="formDataToSend"
            :value="formDataToSend.size"
          />
        </div>

        <p class="form-create__label">Formats</p>
        <div class="input-container">
          <MainSelect
            :id="'type'"
            :placeholder="'Choose'"
            :option-list="typesList"
            :set-value="formDataToSend"
            :value="formDataToSend.type"
          />
        </div>

        <p class="form-create__label">Comment</p>
        <div class="input-container">
          <TextEditor
            :set-value="formDataToSend"
            :id="'comment'"
            :placeholder="''"
            :error-messages="validationErrors['comment']"
          />
        </div>

        <div class="list__confirm-buttons">
          <div class="list__confirm-button">
            <MainButton buttonWhite @click.native="handleCloseCreateModal" :button-cancel="true"
              >CANCEL</MainButton
            >
          </div>
          <div v-if="createModal" class="list__confirm-button">
            <MainButton @click.native="handleCreateTask">CREATE</MainButton>
          </div>
          <div v-if="editModal" class="list__confirm-button">
            <MainButton @click.native="handleEditTask">EDIT</MainButton>
          </div>
        </div>
      </div>
    </MainModal>

    <MainModal v-if="deleteModalSuccess" :on-close="handleCloseSuccessModal">
      <div class="list__success-delete">Task successfully deleted.</div>
    </MainModal>
    <FetchSpinnerModal v-if="loadingProcess" />
  </Main>
</template>

<script>
import { mapState } from "vuex";
import ROUTE from "~/constants/routes";
import templateApi from "~/api/templates";
import moment from "moment";
import { debounce } from "lodash";
import { getSite } from "~/helpers/userAuth";

import Main from "~/templates/Main.vue";
import FetchSpinnerModal from "~/components/molecules/FetchSpinnerModal.vue";
import ToolsPanel from "~/components/molecules/ToolsPanel.vue";
import ContentContainer from "~/components/molecules/ContentContainer.vue";
import MainButton from "~/components/atoms/buttons/MainButton.vue";
import MainModal from "~/components/molecules/MainModal.vue";
import ModalError from "~/components/molecules/ModalError.vue";
import MainSelect from "~/components/atoms/inputs/MainSelect.vue";
import InputTextarea from "~/components/atoms/inputs/InputTextarea.vue";
import Notification from "~/components/molecules/Notification.vue";
import MainCheckbox from "~/components/atoms/inputs/MainCheckbox.vue";
import InputText from "~/components/atoms/inputs/InputText.vue";
import IconSelect from "~/assets/images/icons/select-icon-small.svg";
import IconCancel from "~/assets/images/icons/cancel-icon.svg";
import TextEditor from "~/components/atoms/inputs/TextEditor.vue";

export default {
  name: "PlanItems",
  metaInfo: {
    title: "Month Plan",
  },
  data() {
    return {
      publicUrl: "",
      plansList: [],
      keywordList: false,
      keywordArray: [],
      plans: null,
      deleteModal: false,
      deleteID: null,
      deleteModalSuccess: false,
      directionSort: true,
      orderSort: "id",
      createModal: false,
      editModal: false,
      validationErrors: {},
      siteId: null,
      filterCategoryName: null,
      filterCategoryId: null,
      triggerListCategory: false,
      isCategoryStatistic: false,
      sizesList: [],
      typesList: [],
      formDataToSend: {
        content_plan_id: null,
        executor_id: null,
        keyword_name: "",
        keyword_id: null,
        task_id: null,
        comment: "",
        type: null,
        size: null,
        premium: false,
        is_new_product: true,
        suggested_template_name: "",
        item_type: null,
        template_url: "",
        template_id: null,
        attributes_filled: false,
        attributes: {},
        seasonality: [],
      },
      attributesArray: [],
      monthList: [
        { id: 1, name: "January" },
        { id: 2, name: "February" },
        { id: 3, name: "March" },
        { id: 4, name: "April" },
        { id: 5, name: "May" },
        { id: 6, name: "June" },
        { id: 7, name: "July" },
        { id: 8, name: "August" },
        { id: 9, name: "September" },
        { id: 10, name: "October" },
        { id: 11, name: "November" },
        { id: 12, name: "December" },
      ],
      yearList: [],
    };
  },
  components: {
    Main,
    FetchSpinnerModal,
    ToolsPanel,
    ContentContainer,
    MainButton,
    MainModal,
    ModalError,
    Notification,
    MainSelect,
    MainCheckbox,
    InputText,
    IconSelect,
    IconCancel,
    TextEditor,
  },
  mounted() {
    this.getList();
    // this.getSizes();
    // this.getTypes();
    this.getPlansItemsTypes();

    for (let i = 0; i <= 2; i++) {
      const year = +moment().format("YYYY") + i;
      this.yearList.push({ id: year, name: `${year}` });
    }

    this.siteId = getSite().id;

    if (this.siteId == 1) {
      this.publicUrl = "https://thegoodocs.com";
    } else if (this.siteId == 2) {
      this.publicUrl = "https://www.elegantflyer.com";
    }

    this.getCategoriesList();
  },
  computed: {
    ...mapState("templates", ["error", "loadingProcess"]),
    ...mapState("references", ["templatesSizesList", "templatesTypesList", "plansItemsTypesList"]),
    ...mapState("categories", ["categoriesTree"]),

    page() {
      return +this.$route.params.page;
    },
  },
  methods: {
    getMonthString(data) {
      if (data && data.length > 0) {
        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
          "NO Data",
        ];
        const result = data.map((num) => monthNames[num - 1]).join(", ");
        return result;
      }
    },
    getCategoriesList() {
      this.$store.dispatch("categories/getCategoriesTree");
    },
    handleClearCategoryFilter() {
      this.filterCategoryId = null;
      this.filterCategoryName = null;
      this.getList();
    },
    handleCloseListCategory() {
      this.triggerListCategory = false;
    },
    handleOpenListCategory() {
      if (this.triggerListCategory) {
        this.triggerListCategory = false;
      } else {
        this.triggerListCategory = true;
      }
    },
    handleChooseCategory(category) {
      this.filterCategoryName = category.name;
      this.filterCategoryId = category.id;
      this.handleCloseListCategory();
      this.getList();
    },
    getSizes() {
      this.$store.dispatch("references/getTemplatesSizesAll");
    },
    getTypes() {
      this.$store.dispatch("references/getTemplatesTypes");
    },
    getPlansItemsTypes() {
      this.$store.dispatch("references/getPlansItemsTypes");
    },
    getList() {
      this.$store.commit("templates/setLoading", true);

      let url = `/${this.$route.params.id}?appendPlannedTemplatesByCategory=1&appendStatistics=1&appendKeywordAhrefsLinks=1`;

      if (this.filterCategoryId) {
        url += `&category_id=${this.filterCategoryId}`;
      }

      templateApi
        .getPlanItems(url)
        .then((res) => {
          this.$store.commit("templates/setLoading", false);
          if (res.success) {
            this.plans = res.data.items;
            this.plansList = res.data.items.executors;
            this.plans.month = moment(this.plans.endDate).format("MMMM");
            this.plans.year = moment(this.plans.endDate).format("YYYY");
          }
        })
        .catch((error) => {
          this.$store.commit("templates/setLoading", false);
          console.log(error);
        });
    },

    handleDelete(id) {
      this.deleteModal = true;
      this.deleteID = id;
    },
    cleanForm() {
      this.formDataToSend.executor_id = null;
      this.formDataToSend.content_plan_id = null;
      this.formDataToSend.keyword_id = null;
      this.formDataToSend.keyword_name = "";
      this.formDataToSend.comment = "";
      this.formDataToSend.task_id = null;
      this.formDataToSend.premium = false;
      this.formDataToSend.type = null;
      this.formDataToSend.size = null;
      this.formDataToSend.suggested_template_name = "";
      this.formDataToSend.is_new_product = true;
      this.formDataToSend.item_type = null;
      this.formDataToSend.template_url = "";
      this.formDataToSend.template_id = null;
      this.formDataToSend.attributes_filled = false;
      this.formDataToSend.attributes = {};
      this.formDataToSend.seasonality = [];
      this.attributesArray = [];
      this.sizesList = [];
      this.typesList = [];

      this.validationErrors = {};
      this.deleteID = null;
    },
    handleCreate(id) {
      this.cleanForm();

      this.formDataToSend.executor_id = id;
      this.formDataToSend.content_plan_id = this.$route.params.id;
      this.createModal = true;
    },
    handleEdit(id, task_id, elem) {
      console.log(elem);

      this.cleanForm();

      this.formDataToSend.executor_id = id;
      this.formDataToSend.task_id = task_id;
      this.formDataToSend.keyword_id = elem.keyword.id;
      this.formDataToSend.keyword_name = elem.keyword.keyword;
      this.formDataToSend.comment = elem.comment;
      this.formDataToSend.premium = elem.premium;
      this.formDataToSend.type = elem.type;
      this.formDataToSend.size = elem.size;
      this.formDataToSend.seasonality = elem.keyword.seasonality;

      this.formDataToSend.content_plan_id = this.$route.params.id;

      this.formDataToSend.is_new_product = elem.isNewProduct ? true : false;
      this.formDataToSend.item_type = elem.itemType;
      this.formDataToSend.suggested_template_name = elem.suggestedTemplateName;
      this.formDataToSend.attributes_filled = elem.attributesFilled;

      if (elem.templateId) {
        this.formDataToSend.template_id = elem.templateId;
      }

      this.formDataToSend.attributes_filled = elem.keyword.attributesFilled;

      this.formDataToSend.attributes = {};
      this.attributesArray = [];

      if (elem.keyword.allowedSizes.length && elem.keyword.allowedSizes[0].length) {
        this.sizesList = elem.keyword.allowedSizes[0];
      }

      if (elem.keyword.allowedTypes.length && elem.keyword.allowedTypes[0].length) {
        this.typesList = elem.keyword.allowedTypes[0];
      }

      if (elem.attributes.length > 0) {
        const attributes = elem.attributes?.[0];
        this.attributesArray = elem.attributes?.[0];

        for (let i = 0; attributes.length > i; i++) {
          this.$set(this.formDataToSend.attributes, attributes[i].id, true);
        }

        if (elem.keyword.allowedAttributes.length > 0) {
          const attributesKeyword = elem.keyword.allowedAttributes[0];
          this.attributesArray = attributesKeyword;

          for (let a = 0; attributesKeyword.length > a; a++) {
            if (this.formDataToSend.attributes[attributesKeyword[a].id] == undefined) {
              this.$set(this.formDataToSend.attributes, attributesKeyword[a].id, false);
            }
          }
        }
      }

      this.editModal = true;
    },
    handleCloseConfirmModal() {
      this.deleteModal = false;
    },
    handleDeleteTask() {
      const url = `/${this.deleteID}`;
      this.$store.commit("templates/setLoading", true);
      templateApi
        .deletePlanItem(url)
        .then((res) => {
          this.$store.commit("templates/setLoading", false);
          if (res.success) {
            this.getList();
            this.handleCloseConfirmModal();
            this.deleteModalSuccess = true;
          }
        })
        .catch((error) => {
          this.$store.commit("templates/setLoading", false);
          console.log(error);
        });
    },
    handleCloseSuccessModal() {
      this.deleteModalSuccess = false;
    },
    closeModal() {
      this.error = null;
    },

    handleCloseCreateModal() {
      this.cleanForm();

      this.createModal = false;
      this.editModal = false;
    },

    goToTemplate(id) {
      return ROUTE.TEMPLATE_EDIT.replace(":id", `${id}`);
    },

    goToKeyword(id) {
      return ROUTE.KEYWORDS_EDIT.replace(":id", `${id}`);
    },

    getDifferenceInDays(date) {
      const givenDate = moment(date, "YYYY-MM-DD HH:mm:ss");
      const currentDate = moment();
      let differenceInDays = currentDate.diff(givenDate, "days");
      if (differenceInDays < 2) {
        differenceInDays += " day";
      } else {
        differenceInDays += " days";
      }
      return differenceInDays;
    },

    handleCreateTask() {
      const data = {};
      if (this.formDataToSend.content_plan_id) {
        data.content_plan_id = +this.formDataToSend.content_plan_id;
      }
      if (this.formDataToSend.executor_id) {
        data.executor_id = this.formDataToSend.executor_id;
      }
      if (this.formDataToSend.keyword_id) {
        data.keyword_id = this.formDataToSend.keyword_id;
      }
      if (this.formDataToSend.keyword_id) {
        data.comment = this.formDataToSend.comment;
      }
      if (this.formDataToSend.type) {
        data.type_id = this.formDataToSend.type.id;
      }
      if (this.formDataToSend.size) {
        data.size_id = this.formDataToSend.size.id;
      }

      data.premium = this.formDataToSend.premium;

      data.is_new_product = this.formDataToSend.is_new_product;
      data.suggested_template_name = this.formDataToSend.suggested_template_name;

      if (this.formDataToSend.item_type) {
        data.item_type_id = this.formDataToSend.item_type.id;
      }

      if (this.formDataToSend.template_id && !this.formDataToSend.is_new_product) {
        data.template_id = this.formDataToSend.template_id;
      }

      const attributes = Object.keys(this.formDataToSend.attributes);
      if (attributes.length) {
        let attributesSend = [];
        for (let i = 0; attributes.length > i; i++) {
          if (this.formDataToSend.attributes[attributes[i]] == true) {
            attributesSend.push(attributes[i]);
          }
        }
        if (attributesSend.length > 0) {
          data.attributes = attributesSend;
        }
      }

      console.log(data);

      this.$store.commit("templates/setLoading", true);
      templateApi
        .createPlanItem(data)
        .then((res) => {
          this.$store.commit("templates/setLoading", false);
          if (res.success) {
            this.getList();
            this.handleCloseCreateModal();
          }
        })
        .catch((error) => {
          this.$store.commit("templates/setLoading", false);
          console.log(error);
          const { data } = error.response;
          if (data.errors) {
            this.validationErrors = data.errors;
          }
        });
    },

    getColor(elem) {
      if (elem.itemType.id == 4) {
        if (elem.template.secondaryStatus) {
          if (elem.template.secondaryStatus.id == 6) {
            return "green";
          } else if (elem.template.secondaryStatus.id == 2) {
            return "yellow";
          } else if (elem.template.secondaryStatus.id == 8) {
            return "blue";
          }
        } else {
          return "red";
        }
      } else {
        if (elem.template) {
          if (elem.template.status.id == 6) {
            return "green";
          } else if (elem.template.status.id == 2) {
            return "yellow";
          } else if (elem.template.status.id == 8) {
            return "blue";
          }
        } else {
          return "red";
        }
      }
    },

    handleEditTask() {
      const data = {};
      const url = `/${this.formDataToSend.task_id}`;
      if (this.formDataToSend.content_plan_id) {
        data.content_plan_id = +this.formDataToSend.content_plan_id;
      }
      if (this.formDataToSend.executor_id) {
        data.executor_id = this.formDataToSend.executor_id;
      }
      if (this.formDataToSend.keyword_id) {
        data.keyword_id = this.formDataToSend.keyword_id;
      }
      if (this.formDataToSend.keyword_id) {
        data.comment = this.formDataToSend.comment;
      }

      if (this.formDataToSend.type) {
        data.type_id = this.formDataToSend.type.id;
      }
      if (this.formDataToSend.size) {
        data.size_id = this.formDataToSend.size.id;
      }

      data.premium = this.formDataToSend.premium;

      data.is_new_product = this.formDataToSend.is_new_product;
      data.suggested_template_name = this.formDataToSend.suggested_template_name;

      if (this.formDataToSend.item_type) {
        data.item_type_id = this.formDataToSend.item_type.id;
      }

      if (this.formDataToSend.template_id && !this.formDataToSend.is_new_product) {
        data.template_id = this.formDataToSend.template_id;
      }

      const attributes = Object.keys(this.formDataToSend.attributes);
      if (attributes.length) {
        let attributesSend = [];
        for (let i = 0; attributes.length > i; i++) {
          if (this.formDataToSend.attributes[attributes[i]] == true) {
            attributesSend.push(attributes[i]);
          }
        }
        if (attributesSend.length > 0) {
          data.attributes = attributesSend;
        } else {
          data.attributes = [];
        }
      } else {
        data.attributes = [];
      }

      console.log(data);

      this.$store.commit("templates/setLoading", true);
      templateApi
        .editPlanItem(url, data)
        .then((res) => {
          this.$store.commit("templates/setLoading", false);
          if (res.success) {
            this.getList();
            this.handleCloseCreateModal();
          }
        })
        .catch((error) => {
          this.$store.commit("templates/setLoading", false);
          console.log(error);
          const { data } = error.response;
          if (data.errors) {
            this.validationErrors = data.errors;
          }
        });
    },

    handleFocus() {
      this.keywordList = true;
      this.debouncedHandleInput();
    },
    handleBlur() {
      setTimeout(() => {
        this.keywordList = false;
        this.keywordArray = [];
      }, 500);
    },
    setTemplate() {
      const url = `?url=${this.formDataToSend.template_url}`;
      try {
        templateApi
          .templatesGetByUrl(url)
          .then((res) => {
            const template = res.data.data.item;
            this.formDataToSend.template_id = template.id;
            this.formDataToSend.suggested_template_name = template.name;

            if (template.keyword) {
              this.formDataToSend.keyword_id = template.keyword.id;
              this.formDataToSend.keyword_name = template.keyword.keyword;
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {});
      } catch (e) {}
    },
    handleInput() {
      const data = {};
      data.search = this.formDataToSend.keyword_name.trim();
      data.perPage = 20;
      templateApi
        .getKeywordsListFilter(``, data)
        .then((res) => {
          this.keywordArray = res.data.data.items.data;
          this.keywordArray.sort((a, b) => {
            if (a.keyword < b.keyword) {
              return -1;
            }
            if (a.keyword > b.keyword) {
              return 1;
            }
            return 0;
          });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },
    debouncedHandleInput: debounce(function () {
      this.handleInput();
    }, 300),
    handleChooseItem(item) {
      this.formDataToSend.keyword_id = item.id;
      this.formDataToSend.keyword_name = item.keyword;
      this.formDataToSend.attributes_filled = item.attributesFilled;
      this.formDataToSend.seasonality = item.seasonality;

      console.log(item);

      this.formDataToSend.attributes = {};
      this.attributesArray = [];

      if (item.allowedAttributes.length > 0) {
        const attributes = item.allowedAttributes?.[0];
        this.attributesArray = item.allowedAttributes?.[0];

        for (let i = 0; attributes.length > i; i++) {
          this.$set(this.formDataToSend.attributes, attributes[i].id, false);
        }
      }

      if (item.allowedSizes.length && item.allowedSizes[0].length) {
        this.sizesList = item.allowedSizes[0];
      }

      if (item.allowedTypes.length && item.allowedTypes[0].length) {
        this.typesList = item.allowedTypes[0];
      }
    },
    getCount(type_id, items) {
      let count = 0;
      if (items.length) {
        for (let i = 0; items.length > i; i++) {
          if (items[i]?.itemType?.id == type_id) {
            count++;
          }
        }
      }
      return count;
    },
    openCategoryStatistic() {
      this.isCategoryStatistic = !this.isCategoryStatistic;
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.content-container {
  max-height: calc(100vh - 160px);
}
.checkbox-container__item {
  padding-bottom: 10px;
}
.table-category {
  padding: 0 0 20px;

  &__button {
    display: flex;
    border: 1px solid black;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    width: fit-content;
    cursor: pointer;
  }

  &__button-icon {
    margin-left: 20px;

    &.active {
      transform: rotate(180deg);
    }
  }

  table {
    width: 100%;
  }

  td {
    padding: 10px;
  }

  td:not(:first-child) {
    text-align: center;
  }

  tr.header {
    background: #6cb3ff !important;

    td {
      font-weight: 700;
    }
  }

  tr:nth-child(odd) {
    background: gainsboro;
  }

  td {
    border: 1px solid black;
  }
}
.list {
  &__sort-items {
    display: flex;
    padding: 10px 0;
  }

  &__sort-item-container {
    position: relative;
  }

  &__sort-item-toggle {
    display: flex;
    justify-content: space-between;
    width: 250px;
    padding: 10px;
    border: 1px solid $mainDisabled;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  &__sort-item-icon {
    &.active {
      transform: rotate(-180deg);
    }
  }

  &__sort-item-list {
    @include scrollBar;

    position: absolute;
    padding: 15px;
    max-height: 300px;
    width: 100%;
    overflow-x: auto;
    background: $g-color-white;
    z-index: 1000;
    border: 1px solid $mainDisabled;
    border-radius: 3px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);

    &_second {
      padding-left: 10px;
    }

    &_three {
      padding-left: 20px;
    }

    &_four {
      padding-left: 20px;
    }
  }
  &__sort-clear {
    position: absolute;
    top: 10px;
    right: 30px;
    cursor: pointer;

    svg {
      path {
        fill: $mainDisabled;
      }
    }
  }

  &__list-item-container {
    padding-bottom: 5px;
    font-weight: bold;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  }
  &__top {
    font-size: 36px;
    font-weight: 700;
  }

  &__item-top-title {
    font-size: 20px;
    padding-bottom: 5px;
  }

  &__type-title {
    font-size: 24px;
    font-weight: 700;
    padding: 10px;
    position: sticky;
    left: -20px;
    width: fit-content;
  }

  &__type-item {
    width: fit-content;
    padding: 10px 0;
    border-radius: 10px;

    &:nth-child(even) {
      background: rgb(233, 233, 233);
    }
  }

  &__items {
    display: flex;

    &.sticky {
      position: sticky;
      top: -30px;
      z-index: 100;
    }
  }

  &__item-element-keyword {
    padding-bottom: 5px;
  }

  &__item-element-link {
    display: block;
    cursor: pointer;
    color: #3a80f9;
    text-decoration: underline;
    padding: 0 0 5px;
    text-decoration: none;

    span {
      color: #000;
    }

    &.with-icon {
      display: flex;
      align-items: center;
    }
  }
  &__item-element-link-icon {
    width: 22px;
    height: 22px;
    border: 2px solid red;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    color: red;
    font-weight: 700;
    text-decoration: none;
    border-radius: 50px;
  }

  &__item {
    min-width: 300px;
    width: 300px;
    margin: 0 10px 0 0;
    display: flex;
    flex-direction: column;

    // &:nth-child(odd) {
    //   .list__item-element:nth-child(odd) {
    //     background: rgb(231, 230, 230);
    //   }
    // }

    // &:nth-child(even) {
    //   .list__item-element:nth-child(even) {
    //     background: rgb(231, 230, 230);
    //   }
    // }
  }

  &__item-count {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(160, 160, 160);
    border-radius: 50px;
    margin: 0 auto 10px;
  }

  &__item-top {
    font-size: 14px;
    font-weight: 700;
    padding: 10px 10px;
    background: rgb(182, 182, 182);
    text-align: center;
    border-radius: 10px;
  }

  &__item-element {
    margin: 5px 0;
    padding: 10px 40px 10px 10px;
    position: relative;
    background: rgba(204, 203, 203, 0.3);
    border-radius: 10px;

    &.red {
      background: rgba(255, 0, 0, 0.3);
    }

    &.green {
      background: rgba(1, 255, 1, 0.3);
    }

    &.yellow {
      background: rgba(240, 240, 13, 0.3);
    }

    &.blue {
      background: rgba(13, 225, 240, 0.3);
    }
  }

  &__item-nav {
    padding: 5px 0 0;
    display: flex;
    justify-content: center;
  }

  &__item-nav-button {
    background: #3a80f9;
    width: fit-content;
    padding: 10px;
    cursor: pointer;
    color: #fff;
    border-radius: 10px;
  }

  &__item-edit {
    position: absolute;
    top: 5px;
    right: 0;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: #3a80f9;
    border-radius: 50px;
  }

  &__item-delete {
    position: absolute;
    bottom: 5px;
    right: 0;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: #3a80f9;
    border-radius: 50px;
  }

  &__item-top-statistic {
    padding-top: 5px;
    display: flex;
    justify-content: center;
  }

  &__item-top-statistic-icon {
    width: 35px;
    height: 35px;
    margin: 0 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: grey;
    border-radius: 50px;
    font-size: 12px;

    &.red {
      background: rgb(238, 85, 85);
    }

    &.green {
      background: rgb(58, 204, 1);
    }
  }

  &__progress {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 40px;
  }

  &__progress-item {
    margin-right: 20px;
    padding-top: 15px;
  }

  &__progress-item-title {
    font-size: 18px;
    padding-bottom: 5px;
  }

  &__progress-bar-container {
    width: 300px;
    height: 10px;
    background: rgb(207, 207, 207);
    border-radius: 10px;
    position: relative;
  }

  &__progress-bar-inner {
    position: absolute;
    top: 0;
    left: 0;
    height: 10px;
    border-radius: 10px;
    background: rgb(3, 223, 3);
  }

  &__progress-bar-desc {
    color: grey;
    padding-top: 5px;
  }

  &__item-elem-badge {
    padding: 5px;
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    border-radius: 5px;
    width: fit-content;
    margin-bottom: 10px;

    &.primary {
      background: #007bff;
    }

    &.warning {
      background: #dc3545;
    }
  }

  &__item-element-text-season {
    width: fit-content;
    padding: 5px;
    border-radius: 5px;
    background: #ffc002;
    margin: 5px 0;
  }
}
.form-create {
  &__keyword-input {
    width: 100%;
    font-size: 18px;
    padding: 5px 10px;
    margin-bottom: 10px;

    &.filled {
      outline: 2px solid rgb(10, 199, 10);
    }
  }

  &__keyword-text {
    padding: 10px 15px;
    border-radius: 5px;

    &.green {
      color: #155724;
      background-color: #d4edda;
      border-color: #c3e6cb;
    }

    &.red {
      color: #721c24;
      background-color: #f8d7da;
      border-color: #f5c6cb;
    }
  }

  &__keyword {
    position: relative;
    margin-bottom: 10px;
  }

  &__keyword-list {
    position: absolute;
    left: 0;
    top: calc(100% + 5px);
    border: 1px solid gray;
    background: #fff;
    padding: 10px 0 10px;
    z-index: 10;
  }

  &__keyword-item {
    font-size: 16px;
    padding: 5px 20px;
    cursor: pointer;

    &:hover {
      background: rgb(216, 213, 213);
    }
  }

  &__button-group {
    display: flex;
    padding-bottom: 20px;

    button {
      width: fit-content;
    }
  }
}
</style>
